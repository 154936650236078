/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Subtitle, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Práce"}>
        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Text className="swp-T00-mobile text-box text-box--right mt--02" style={{"maxWidth":497,"marginTop":null}} content={"Jestli potřebujete pomoct, v pravým horním rohu naleznete nápovědu"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":488}} content={"Sekci smažete otevřením editačního panelu kliknutím na tlačítko „Sekce“ a následným kliknutím na tlačítko „Smazat“"}>
              </Text>

              <Subtitle className="subtitle-box mt--25" style={{"marginTop":null}} content={"<span style=\"color: rgb(142, 146, 172);\">PROJEKTANT TZB</span>"}>
              </Subtitle>

              <Title className="title-box mt--06" style={{"maxWidth":620,"marginTop":null}} content={"Začněte tím, že tuhle sekci smažete a přidáte si sekci vlastní."}>
              </Title>

              <Image className="--right mt--40" alt={""} src={"https://cdn.swbpg.com/o/2124/0939ff1d9f92402f8a460fa8a39064fd.svg"} svg={false} sizes="100vw" style={{"maxWidth":45,"marginTop":null}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--right mt--02" style={{"maxWidth":429,"marginTop":null}} content={"Pro přidání nové sekce klikněte na \"+\"<br>tam, kde chcete sekci přidat."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}